import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/contac/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/experiences/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/info/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/tags/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/blog-posts/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(site)/components/projects/styles.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/active-link/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/carousel/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header-button/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/success-dialog/index.tsx")